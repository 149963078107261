<template>
  <div style="margin: 20px">
    <div style="display: flex; align-items: center; margin: 20px 0">
      <lay-date-picker
        placeholder="请选择日期"
        allowClear
        @change="change"
        v-model="endTime"
      ></lay-date-picker>

      <a
        :href="`https://billapi.0.cr/download?group=${group}&timestamp=${timest(endTime)}`"
        target="_blank"
        style="margin-left: 20px; color: blue"
        >下载Excel数据</a
      >
    </div>
    <lay-row>
      <lay-col md="12">
        <div style="padding: 20px">
          <lay-line
            contentPosition="left"
            style="font-weight: 900; font-size: 20px"
            >入款（{{ total_amount }}笔）</lay-line
          >
        </div>
        <lay-table
          :columns="columns"
          :data-source="dataSource"
          :resize="true"
        ></lay-table>
        <br />
        <br />
        <lay-collapse>
          <lay-collapse-item title="入款汇率分类" id="1">
            <lay-table
              :columns="columns3"
              :data-source="dataSource4"
              :resize="true"
            ></lay-table>
          </lay-collapse-item>
        </lay-collapse>
        <br />
        <div style="padding: 20px">
          <lay-line
            contentPosition="left"
            style="font-weight: 900; font-size: 20px"
            >下发（{{ total_amount2 }}笔）</lay-line
          >
        </div>
        <lay-table
          :columns="columns"
          :data-source="dataSource2"
          :resize="true"
        ></lay-table>
        <br />
        <br />
        <div style="padding: 20px">
          <lay-line
            contentPosition="left"
            style=" font-weight: 900; font-size: 20px;ba"
            >总结</lay-line
          >
        </div>
        <lay-table
          :columns="columns2"
          :data-source="dataSource3"
          :resize="true"
        >
        </lay-table>
      </lay-col>
    </lay-row>
    <!-- <div style="margin-top: 20px">
      <lay-button type="default" size="lg" @click="per">上一页</lay-button>
      <lay-button type="default" size="lg" @click="next">下一页</lay-button>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import { toRefs, reactive } from "vue";

export default {
  name: "App",
  setup() {
    const timest = (e) => {
      var tmp = Date.parse(e).toString();
      tmp = tmp.substr(0, 10);
      return tmp;
    };
    const queryURLParams = (url) => {
      let pattern = /(\w+)=(\w+)/gi; //定义正则表达式
      let parames = {}; // 定义参数对象
      url.replace(pattern, ($, $1, $2) => {
        parames[$1] = $2;
      });
      return parames;
    };
    const columns = [
      {
        title: "时间",
        align: "center",
        key: "time",
      },
      {
        title: "金额",
        align: "center",
        key: "amount",
      },
      {
        title: "",
        align: "center",
        key: "calculate",
      },

      {
        title: "回复人",
        align: "center",
        key: "reply",
      },
      {
        title: "操作人",
        align: "center",
        key: "operator",
      },
    ];
    const columns2 = [
      {
        title: "入款总数：",

        key: "label",
      },
      {
        title: "0",

        key: "value",
      },
    ];
    const dataSource = [];
    const dataSource2 = [];
    const dataSource3 = [
      { label: "应下发：", value: "0" },
      { label: "下发总数：", value: "0.00" },
      { label: "未下发：", value: "0.00" },
    ];

    const columns3 = [
      {
        title: "汇率",
        align: "center",
        key: "value1",
      },
      {
        title: "金额",
        align: "center",
        key: "value2",
      },
      {
        title: "换算",
        align: "center",
        key: "value3",
      },
    ];
    const dataSource4 = [];
    const state = reactive({
      columns,
      columns2,
      columns3,
      dataSource,
      dataSource2,
      dataSource3,
      dataSource4,
      endTime: new Date(),
      total_amount: 0,
      total_amount2: 0,
      group:null
      // page: 1,
    });
    console.log(new Date().getTime() / 1000);

    const init = () => {
  
     const obj=queryURLParams(window.location.href)
     state.group=obj.group
     console.log(obj)
      axios
        .get(
            `http://127.0.0.1:8000/api/bill/?group=${obj.group}&timestamp=${timest(
              new Date(state.endTime)
            )}&page=${obj.page}`
          )
        // .get(
        //   `/api/bill/?group=${obj.group}&timestamp=${timest(
        //     new Date()
        //   )}&page=${obj.page}`
        // )
        .then((res) => {
          console.log(res.data);
          if (res.data.code === 1) {
            state.columns2 = [
              {
                title: "费率",
                align: "center",
                key: "label",
              },
              {
                title: res.data.data.deposit.rate,
                align: "center",
                key: "value",
              },
            ];
            state.dataSource = res.data.data.deposit.data;
            state.dataSource2 = res.data.data.payment.data;
            state.dataSource4 = [];
            res.data.data.Classification.data.forEach((object) => {
              for (const key in object) {
                if (Object.hasOwnProperty.call(object, key)) {
                  const element = object[key];
                  state.dataSource4.push({
                    value1: (key == "0.0") ? "1.0" : key,
                    value2: element,
                    value3: (Number(key) == 0.0) ? (element / 1.0).toFixed(2) + " U" : (element / Number(key)).toFixed(2) + " U",
                  });
                }
              }
            });
            state.dataSource3 = [
              {
                label: "美元费率",
                value: res.data.data.deposit.usd_rate,
              },
              {
                label: "入款总数：",
                value: res.data.data.deposit.total_amount,
              },
              {
                label: "应下发：",
                value: res.data.data.deposit.transfer_amount,
              },
              {
                label: "下发总数：",
                value: res.data.data.payment.total_amount,
              },
              {
                label: "未下发：",
                value: res.data.data.payment.transfer_amount,
              },
            ];
            state.total_amount = res.data.data.deposit.total;
            state.total_amount2 = res.data.data.payment.total;
            console.log(state.dataSource, "state");
          }
        });
    };
    // onMounted(() => {
    //   init(timest(state.endTime));
    // });
    const change = () => {
      init();
    };
    const per = () => {
      if (state.page <= 1) {
        return;
      }
      state.page--;
      init();
    };
    const next = () => {
      state.page++;
      init();
    };
    return {
      ...toRefs(state),
      change,
      per,
      next,
      timest
    };
  },
};
</script>

<style>
.layui-table-header .layui-table-cell {
  background: #fff !important;
}
.layui-colla-content {
  padding: 0 !important;
}
</style>
